import { template as template_edeb2f963a3f44e38157861d30223c07 } from "@ember/template-compiler";
const FKLabel = template_edeb2f963a3f44e38157861d30223c07(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
