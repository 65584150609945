import { template as template_c142b8f10ae14fb0a0570c1c469543f0 } from "@ember/template-compiler";
const FKText = template_c142b8f10ae14fb0a0570c1c469543f0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
