import { template as template_4ababbc78ab4476dacffa3229d4d740c } from "@ember/template-compiler";
const WelcomeHeader = template_4ababbc78ab4476dacffa3229d4d740c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
