
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("discourse-common/config/environment", function(){ return i("discourse-common/config/environment");});
d("discourse-common/deprecation-workflow", function(){ return i("discourse-common/deprecation-workflow");});
d("discourse-common/lib/attribute-hook", function(){ return i("discourse-common/lib/attribute-hook");});
d("discourse-common/lib/avatar-utils", function(){ return i("discourse-common/lib/avatar-utils");});
d("discourse-common/lib/case-converter", function(){ return i("discourse-common/lib/case-converter");});
d("discourse-common/lib/debounce", function(){ return i("discourse-common/lib/debounce");});
d("discourse-common/lib/deprecated", function(){ return i("discourse-common/lib/deprecated");});
d("discourse-common/lib/discourse-template-map", function(){ return i("discourse-common/lib/discourse-template-map");});
d("discourse-common/lib/dom-from-string", function(){ return i("discourse-common/lib/dom-from-string");});
d("discourse-common/lib/escape", function(){ return i("discourse-common/lib/escape");});
d("discourse-common/lib/get-owner", function(){ return i("discourse-common/lib/get-owner");});
d("discourse-common/lib/get-url", function(){ return i("discourse-common/lib/get-url");});
d("discourse-common/lib/helpers", function(){ return i("discourse-common/lib/helpers");});
d("discourse-common/lib/icon-library", function(){ return i("discourse-common/lib/icon-library");});
d("discourse-common/lib/later", function(){ return i("discourse-common/lib/later");});
d("discourse-common/lib/loader-shim", function(){ return i("discourse-common/lib/loader-shim");});
d("discourse-common/lib/object", function(){ return i("discourse-common/lib/object");});
d("discourse-common/lib/popular-themes", function(){ return i("discourse-common/lib/popular-themes");});
d("discourse-common/lib/raw-handlebars-helpers", function(){ return i("discourse-common/lib/raw-handlebars-helpers");});
d("discourse-common/lib/raw-handlebars", function(){ return i("discourse-common/lib/raw-handlebars");});
d("discourse-common/lib/raw-templates", function(){ return i("discourse-common/lib/raw-templates");});
d("discourse-common/lib/suffix-trie", function(){ return i("discourse-common/lib/suffix-trie");});
d("discourse-common/resolver", function(){ return i("discourse-common/resolver");});
d("discourse-common/utils/decorator-alias", function(){ return i("discourse-common/utils/decorator-alias");});
d("discourse-common/utils/decorators", function(){ return i("discourse-common/utils/decorators");});
d("discourse-common/utils/dom-utils", function(){ return i("discourse-common/utils/dom-utils");});
d("discourse-common/utils/escape-regexp", function(){ return i("discourse-common/utils/escape-regexp");});
d("discourse-common/utils/extract-value", function(){ return i("discourse-common/utils/extract-value");});
d("discourse-common/utils/handle-descriptor", function(){ return i("discourse-common/utils/handle-descriptor");});
d("discourse-common/utils/is-descriptor", function(){ return i("discourse-common/utils/is-descriptor");});
d("discourse-common/utils/macro-alias", function(){ return i("discourse-common/utils/macro-alias");});
d("discourse-common/utils/multi-cache", function(){ return i("discourse-common/utils/multi-cache");});
d("pretty-text/allow-lister", function(){ return i("pretty-text/allow-lister");});
d("pretty-text/censored-words", function(){ return i("pretty-text/censored-words");});
d("pretty-text/emoji", function(){ return i("pretty-text/emoji");});
d("pretty-text/emoji/data", function(){ return i("pretty-text/emoji/data");});
d("pretty-text/emoji/version", function(){ return i("pretty-text/emoji/version");});
d("pretty-text/guid", function(){ return i("pretty-text/guid");});
d("pretty-text/inline-oneboxer", function(){ return i("pretty-text/inline-oneboxer");});
d("pretty-text/mentions", function(){ return i("pretty-text/mentions");});
d("pretty-text/oneboxer-cache", function(){ return i("pretty-text/oneboxer-cache");});
d("pretty-text/oneboxer", function(){ return i("pretty-text/oneboxer");});
d("pretty-text/pretty-text", function(){ return i("pretty-text/pretty-text");});
d("pretty-text/sanitizer", function(){ return i("pretty-text/sanitizer");});
d("pretty-text/text-replace", function(){ return i("pretty-text/text-replace");});
d("pretty-text/upload-short-url", function(){ return i("pretty-text/upload-short-url");});
d("pretty-text/allow-lister", function(){ return i("pretty-text/allow-lister");});
d("pretty-text/censored-words", function(){ return i("pretty-text/censored-words");});
d("pretty-text/emoji", function(){ return i("pretty-text/emoji");});
d("pretty-text/emoji/data", function(){ return i("pretty-text/emoji/data");});
d("pretty-text/emoji/version", function(){ return i("pretty-text/emoji/version");});
d("pretty-text/guid", function(){ return i("pretty-text/guid");});
d("pretty-text/inline-oneboxer", function(){ return i("pretty-text/inline-oneboxer");});
d("pretty-text/mentions", function(){ return i("pretty-text/mentions");});
d("pretty-text/oneboxer-cache", function(){ return i("pretty-text/oneboxer-cache");});
d("pretty-text/oneboxer", function(){ return i("pretty-text/oneboxer");});
d("pretty-text/pretty-text", function(){ return i("pretty-text/pretty-text");});
d("pretty-text/sanitizer", function(){ return i("pretty-text/sanitizer");});
d("pretty-text/text-replace", function(){ return i("pretty-text/text-replace");});
d("pretty-text/upload-short-url", function(){ return i("pretty-text/upload-short-url");});
import "discourse-i18n/-embroider-implicit-modules.js";
import "truth-helpers/-embroider-implicit-modules.js";
import "discourse-i18n/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "discourse-common/-embroider-implicit-modules.js";
import "pretty-text/-embroider-implicit-modules.js";
import "pretty-text/-embroider-implicit-modules.js";
