import { template as template_3860acb516db4df086b1aa8cd9c1dc1a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_3860acb516db4df086b1aa8cd9c1dc1a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
