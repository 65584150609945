import { template as template_89ebf75f8fc644f08bc2d5d626e32a18 } from "@ember/template-compiler";
const FKControlMenuContainer = template_89ebf75f8fc644f08bc2d5d626e32a18(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
